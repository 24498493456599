
import { Options } from "vue-class-component";
import NodesMinxins from "../NodesMixins";
import InsertButton from "../../../components/InsertButton/InsertButton.vue";

@Options({
    name: "flowable-nodes-condition-node",
    components: { InsertButton },
    props: {
        //索引位置
        level: {
            type: Number,
            default: 1,
        },
        //条件数
        size: {
            type: Number,
            default: 0,
        },
    },
    emits: ["leftMove", "selected", "copy", "delNode", "rightMove", "insertNode"],
})
export default class ConditionNode extends NodesMinxins {
    private placeholder = "请设置条件";
    private errorInfo = "";
    private showError = false;
    private level: number;
    private size: number;

    get content() {
        const groups = this.config.props.groups;
        let confitions: any = [];
        groups.forEach((group: any) => {
            let subConditions: any = [];
            group.conditions.forEach((subCondition: any) => {
                let subConditionStr = "";
                switch (subCondition.valueType) {
                    case this.ValueType.org:
                        subConditionStr = `${subCondition.title}属于[${String(subCondition.value.map((u: any) => u.title)).replaceAll(",", ". ")}]组织之一`;
                        break;
                    case this.ValueType.orgType:
                        subConditionStr = `${subCondition.title}属于[${String(subCondition.value.map((u: any) => u.title)).replaceAll(",", ". ")}]组织类型之一`;
                        break;
                    case this.ValueType.deviceOrg:
                        subConditionStr = `${subCondition.title}属于[${String(subCondition.value.map((u: any) => u.title)).replaceAll(",", ". ")}]设备组织之一`;
                        break;
                    case this.ValueType.deviceOrgType:
                        subConditionStr = `${subCondition.title}属于[${String(subCondition.value.map((u: any) => u.title)).replaceAll(",", ". ")}]设备组织类型之一`;
                        break;
                    case this.ValueType.selectOrgType:
                        subConditionStr = `${subCondition.title}属于[${String(subCondition.value.map((u: any) => u.title)).replaceAll(",", ". ")}]所选组织类型之一`;
                        break;
                    case this.ValueType.dept:
                        subConditionStr = `${subCondition.title}属于[${String(subCondition.value.map((u: any) => u.title)).replaceAll(",", ". ")}]部门之一`;
                        break;
                    case this.ValueType.post:
                        subConditionStr = `${subCondition.title}属于[${String(subCondition.value.map((u: any) => u.title)).replaceAll(",", ". ")}]岗位之一`;
                        break;
                    case this.ValueType.user:
                        subConditionStr = `${subCondition.title}属于[${String(subCondition.value.map((u: any) => u.title)).replaceAll(",", ". ")}]用户之一`;
                        break;
                    case this.ValueType.number:
                    case this.ValueType.string:
                        subConditionStr = this.getOrdinaryConditionContent(subCondition);
                        break;
                }
                subConditions.push(subConditionStr);
            });
            //根据子条件关系构建描述
            let subConditionsStr = String(subConditions).replaceAll(
                ",",
                subConditions.length > 1 ? (group.groupType === "AND" ? ") 且 (" : ") 或 (") : group.groupType === "AND" ? " 且 " : " 或 ",
            );
            confitions.push(subConditions.length > 1 ? `(${subConditionsStr})` : subConditionsStr);
        });
        //构建最终描述
        return String(confitions).replaceAll(",", this.config.props.groupsType === "AND" ? " 且 " : " 或 ");
    }
    public getDefault(val: any, df: any) {
        return val && val !== "" ? val : df;
    }
    public getOrdinaryConditionContent(subCondition: any) {
        switch (subCondition.compare) {
            case "IN":
                return `${subCondition.title}为[${String(subCondition.value).replaceAll(",", "、")}]中之一`;
            case "B":
                return `${subCondition.value[0]} < ${subCondition.title} < ${subCondition.value[1]}`;
            case "AB":
                return `${subCondition.value[0]} ≤ ${subCondition.title} < ${subCondition.value[1]}`;
            case "BA":
                return `${subCondition.value[0]} < ${subCondition.title} ≤ ${subCondition.value[1]}`;
            case "ABA":
                return `${subCondition.value[0]} ≤ ${subCondition.title} ≤ ${subCondition.value[1]}`;
            case "<=":
                return `${subCondition.title} ≤ ${this.getDefault(subCondition.value[0], " ?")}`;
            case ">=":
                return `${subCondition.title} ≥ ${this.getDefault(subCondition.value[0], " ?")}`;
            default:
                return `${subCondition.title}${subCondition.compare}${this.getDefault(subCondition.value[0], " ?")}`;
        }
    }
    //校验数据配置的合法性
    public validate(err: any) {
        //console.log("condition children", this.config.children);
        if (!(this.level == this.size && this.size != 0) && !this.config.children?.id) {
            this.showError = true;
            this.errorInfo = "条件分支后不能为空";
            err.push(`条件分支后不能为空`);
            return !this.showError;
        }

        const props = this.config.props;
        if (props.groups.length <= 0) {
            this.showError = true;
            this.errorInfo = "请设置分支条件";
            err.push(`${this.config.name} 未设置条件`);
        } else {
            if (!(this.level == this.size && this.size != 0)) {
                for (let i = 0; i < props.groups.length; i++) {
                    if (props.groups[i].cids.length === 0) {
                        this.showError = true;
                        this.errorInfo = `请设置条件组${this.groupNames[i]}内的条件`;
                        err.push(`条件 ${this.config.name} 条件组${this.groupNames[i]}内未设置条件`);
                        break;
                    } else {
                        let conditions = props.groups[i].conditions;
                        for (let ci = 0; ci < conditions.length; ci++) {
                            let subc = conditions[ci];
                            this.showError = subc.value.length === 0;
                            if (this.showError) {
                                this.errorInfo = `请完善条件组${this.groupNames[i]}内的${subc.title}条件`;
                                err.push(`条件 ${this.config.name} 条件组${this.groupNames[i]}内${subc.title}条件未完善`);
                                return false;
                            }
                            this.showError = ["deviceOrg", "deviceOrgType"].includes(subc.id) && this.$tools.isEmpty(props.formProject);
                            if (this.showError) {
                                this.errorInfo = `条件组${this.groupNames[i]}内的${subc.title}条件与设备相关，请配置表单设备字段`;
                                err.push(`条件 ${this.config.name} 条件组${this.groupNames[i]}内${subc.title}条件与设备相关，请配置表单设备字段`);
                                return false;
                            }
                            this.showError = ["selectOrgType"].includes(subc.id) && this.$tools.isEmpty(props.formOrgCode);
                            if (this.showError) {
                                this.errorInfo = `条件组${this.groupNames[i]}内的${subc.title}条件与组织相关，请配置表单组织字段`;
                                err.push(`条件 ${this.config.name} 条件组${this.groupNames[i]}内${subc.title}条件与组织相关，请配置表单组织字段`);
                                return false;
                            }
                        }
                    }
                }
            }
        }
        return !this.showError;
    }
}
